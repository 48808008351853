import LandingComponent from '../components/LandingComponent';

export default function ErrorPage() {
    return <>
        <LandingComponent />

        {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"> */}
            <div className="bg-gray-800 p-8 rounded-lg">
                <h1 className="text-4xl text-center text-white">404</h1>
                <p className="text-center text-white">Page not found</p>
            </div>
        {/* </div> */}
    </>
}