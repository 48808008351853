import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Pages
import Feed from './components/Feed';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Create from './pages/Create';
import Signup from './pages/Signup';
import Settings from './pages/Settings';
import Search from './pages/Search';
import PageComponent from './pages/PageComponent';
import User from './pages/User';
import Request from './pages/Request';
import ErrorPage from './pages/ErrorPage';
import MessagesPage from './pages/MessagesPage';
import UserPage from './pages/UserPage';
import SettingsAccount from './pages/settings/Account';
import SettingsSecurity from './pages/settings/Security';
import LandingPage from './pages/LandingPage';
import EarningsPage from './pages/Earnings';

import TermsPage from './pages/Terms';
import PrivacyPage from './pages/Privacy';
import RefundsPage from './pages/Refunds';
import ContactPage from './pages/Contact';

// https://www.figma.com/file/CS01VVLR7ArQl0afYFkNj3/Web-App?type=design&node-id=0-1&mode=design&t=sUKVnPNFZRgiHyP8-0

export default function App() {
  return <div className="bg-body relative min-h-screen max-h-full w-full text-white">
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/refunds" element={<RefundsPage />} />
        <Route path="/contact" element={<ContactPage />} />

        {/* <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/settings" element={<SettingsAccount />} /> */}
      </Routes>
    </Router>
  </div>
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <App />
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
