import React from 'react';
import logo from '../GCLOGO.png';
import { Link } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';

type props = {};
type state = {
    loading: boolean;
    showingSolutions: boolean;
}

export default class LandingPage extends React.Component<props, state> {
    private textDiv: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            showingSolutions: false
        }
        this.setShowing = this.setShowing.bind(this);
    }

    async componentDidMount() {
        
    }

    setShowing(showing: boolean) {
        this.setState({showingSolutions: showing});
    }

    render() {
        return <div>
            <header className="flex grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 px-4 py-4">
                <div className="hidden lg:block xl:block 2xl:block">
                    <Link to={`https://guardedcontent.com/`}><img src={logo} width={'200'} className="py-4" /></Link>
                </div>
                <div id="links" className="flex py-2 gap-8 justify-center">
                    <Link to={`https://guardedcontent.com/`} className="text-xl font-bold">Home</Link>
                    {/* <Popover
                        onMouseEnter={() => this.setShowing(true)}
                        onMouseLeave={() => this.setShowing(false)}
                    >
                        <PopoverButton className="text-xl font-bold">Solutions</PopoverButton>
                        <Transition
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                            show={this.state.showingSolutions}
                        >
                            <PopoverPanel
                            anchor="bottom"
                            className="divide-y divide-white/5 rounded-xl bg-white/5 text-sm/6 [--anchor-gap:var(--spacing-5)] mt-2"
                            >
                            <div className="p-3">
                                <a className="block rounded-lg py-2 px-3 transition hover:bg-white/5" href="#">
                                    <p className="font-semibold text-white">General Media</p>
                                    <p className="text-white/50">Protect your content using DRM</p>
                                </a>
                            </div>
                            </PopoverPanel>
                        </Transition>
                    </Popover> */}
                    {/* <Link to={`./pricing`} className="text-xl font-bold">Pricing</Link> */}
                    {/* <Link to={`./faq`} className="text-xl font-bold">FAQ</Link> */}
                    <Link to={`https://guardedcontent.com/terms`} className="text-xl font-bold">Terms</Link>
                    <Link to={`https://guardedcontent.com/privacy`} className="text-xl font-bold">Privacy Policy</Link>
                    <Link to={`https://guardedcontent.com/refunds`} className="text-xl font-bold">Refunds</Link>
                    <Link to={`https://guardedcontent.com/contact`} className="text-xl font-bold">Contact Us</Link>
                </div>
            </header> 
        </div>
    }
}