import LandingComponent from '../components/LandingComponent';

export default function Terms() {
    return <>
        <LandingComponent />
        <div className="p-20">
            <h2 className="text-6xl text-center">
                <strong>Terms and Conditions</strong>
            </h2>
            <p className="text-center mt-4">Last updated: June 18th, 2024</p>
            <br />
            <h3 className="text-4xl">Introduction</h3><br />
            <p>Welcome to GuardedContent (the "Platform"). By accessing or using the Platform, you agree to comply with and be bound by the following terms and conditions (the "Terms"). Please read these Terms carefully. If you do not agree to these Terms, you must not use the Platform.</p>

            <br /><h3 className="text-4xl">1. Definitions</h3><br />
            <ul className="list-disc list-inside">
                <li><span className="font-bold">"Platform":</span> The website and services provided by GuardedContent.</li>
                <li><span className="font-bold">"User":</span> Any individual who accesses or uses the Platform.</li>
                <li><span className="font-bold">"Content Creator":</span> A User who uploads, shares, or sells content on the Platform.</li>
                <li><span className="font-bold">"Content":</span> Any media uploaded by Content Creators, including but not limited to videos, images, articles, and audio files.</li>
                <li><span className="font-bold">"Subscription":</span> A recurring payment model that allows Users to access Content from a specific Content Creator.</li>
                <li><span className="font-bold">"Purchase":</span> A one-time payment to view specific Content from a Content Creator.</li>
            </ul>

            <br /><h3 className="text-4xl">2. User Accounts</h3><br />
            <ul className="list-disc list-inside">
                <li>Users must create an account to access certain features of the Platform.</li>
                <li>Users are responsible for maintaining the confidentiality of their account information.</li>
                <li>Users must notify the Platform immediately of any unauthorized use of their account.</li>
            </ul>

            <br /><h3 className="text-4xl">3. Content Ownership and Responsibility</h3><br />
            <ul className="list-disc list-inside">
                <li>Content Creators retain ownership of the copyright in the Content they upload.</li>
                <li>By uploading Content, Content Creators grant the Platform a non-exclusive, worldwide, royalty-free license to use, display, and distribute the Content on the Platform.</li>
                <li>Content Creators are solely responsible for the Content they upload and must ensure that it does not infringe on any third-party rights.</li>
            </ul>

            <br /><h3 className="text-4xl">4. Content Removal</h3>
            <ul className="list-disc list-inside">
                <li>Content Creators have the right to remove their Content from the Platform at any time.</li>
                <li>If a User has purchased specific Content, they will retain access to that Content even if it is removed from public view by the Content Creator. However, if content is taken down by the Platform, the User will be refunded at expense by the creator.</li>

            </ul>

            <br /><h3 className="text-4xl">5. Purchases and subscriptions</h3><br />
            <ul className="list-disc list-inside">
                <li>Users may purchase the right to view individual pieces of Content or subscribe to a Content Creator to access all their Content.</li>
                <li>All purchases and subscriptions are final and non-refundable.</li>
                <li>Subscriptions will automatically renew unless cancelled by the User prior to the renewal date. Users will be notified 7 days before their subscription is set to renew.</li>
            </ul>

            <br /><h3 className="text-4xl">6. Digital Rights Management (DRM)</h3><br />
            <ul className="list-disc list-inside">
                <li>The Platform provides Digital Rights Management (DRM) services to protect the Content.</li>  
                <li>Any attempt to breach or hack the DRM technology will result in the suspension or removal of the User's account from the Platform.</li>  
                <li>While the Platform employs robust DRM technologies, there is a possibility that Content can still be copied by other means. The Platform is not liable for any unauthorized copying of Content despite these measures.</li>    
            </ul>  

            <br /><h3 className="text-4xl">7. Prohibited Content</h3><br />
            <p>Users must not:</p>
            <ul className="list-disc list-inside">
                <li>Upload content that infringes on any third-party intellectual property rights.</li>
                <li>Use the platform for any illegal activities.</li>
                <li>Harass, threaten, or abuse other users or content creators.</li>
                <li>Attempt to hack or disrupt the platform or its DRM technologies.</li>
                <li>Upload any content that is illegal, pornographic, or promotes self-harm.</li>
            </ul><br />
            <span>The Platform reserves the right to update the list of prohibited Content at any time.</span><br />
            <span>It is the User's responsibility to stay informed about which types of Content are prohibited on the Platform.</span><br />

            <br /><h3 className="text-4xl">8. Termination</h3><br />
            <ul className="list-disc list-inside">
                <li>The Platform reserves the right to suspend or terminate any User account at its sole discretion if these Terms are violated.</li>
                <li>Users may terminate their account at any time by contacting the Platform's support team.</li>
            </ul>

            <br /><h3 className="text-4xl">9. Limitation of Liability</h3><br />
            <ul className="list-disc list-inside">
                <li>The Platform is provided on an "as is" basis without any warranties of any kind.</li>
                <li>The Platform shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of the use or inability to use the Platform.</li>
            </ul>

            <br /><h3 className="text-4xl">10. Indemnification</h3><br />
            <span>Users agree to indemnify and hold the Platform harmless from any claims, damages, or expenses arising out of their use of the Platform or violation of these Terms.</span>

            <br /><h3 className="text-4xl">11. Modifications to the terms</h3><br />
            <ul className="list-disc list-inside">
                <li>The Platform reserves the right to modify these Terms at any time.</li>
                <li>Users will be notified of any significant changes to the Terms.</li>
                <li>Continued use of the Platform after such modifications constitutes acceptance of the new Terms.</li>
            </ul>

            <br /><h3 className="text-4xl">12. Governing Law</h3><br />
            <span>These Terms shall be governed by and construed in accordance with the laws of Australia.</span><br />

            <br /><h3 className="text-4xl">13. Contact Information</h3><br />
            <span>For any questions about these Terms, please contact us at <a href="mailto:info@guardedcontent.com" className="text-blue-500">info@guardedcontent.com</a></span><br />
            <span>By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</span>
        </div>

        <footer className="px-8 py-8 absolute left-0 bottom-0 right-0">
            &copy; GuardedContent, 2024
        </footer>
    </>
}