import LandingComponent from '../components/LandingComponent';

export default function Refunds() {

    return <>
        <LandingComponent />
        <div className="p-20">
            <h2 className="text-6xl text-center">
                <strong>Refund Policy</strong>
            </h2>
            <p className="text-center mt-4">Effective Date: 18/06/2024</p><br />

            <h2 className="text-3xl font-bold mt-2">1. Scope</h2>

            This Returns Policy applies to all memberships and subscription services purchased directly from GuardedContent, an Australia-based media streaming company.

            

            <h2 className="text-3xl font-bold mt-2">2. Eligibility for Returns</h2>

            Members may qualify for cancellations and refunds under the following conditions:

            - The membership or subscription was purchased directly from GuardedContent.

            - Cancellation requests must be made within 30 days of the initial purchase date.

            - The membership has not been used to access any premium content or benefits.

            - Proof of membership purchase, such as a receipt or confirmation email, must be provided.

            

            <h2 className="text-3xl font-bold mt-2">3. Non-eligible Items</h2>

            The following items are not eligible for cancellation or refund:

            - Memberships that have been activated and used to access premium content or benefits.

            - Digital content that has been downloaded or streamed using the membership.

            - Gifted memberships or promotional subscriptions.

            

            <h2 className="text-3xl font-bold mt-2">4. Cancellation Procedure</h2>

            To cancel a membership, members must follow these steps:

            - Contact our customer support at info@guardedcontent.com to request cancellation and obtain confirmation.

            - Ensure all login credentials associated with the membership are deactivated and no longer in use.


            <h2 className="text-3xl font-bold mt-2">5. Refund Process</h2>

            Upon confirming the cancellation of an eligible membership, we will process the refund as follows:

            - Refunds will be issued using the original payment method.

            - The timeframe for processing refunds may vary depending on the payment provider.

            

            <h2 className="text-3xl font-bold mt-2">6. Exceptions</h2>

            - If a membership is cancelled due to technical issues or dissatisfaction with services, please contact us immediately for further assistance.

            - Memberships that include physical goods or special benefits may have specific return or refund terms outlined separately. Please refer to our Membership Terms for details.

            

            <h2 className="text-3xl font-bold mt-2">7. Contact Us</h2>

            For any questions regarding our Membership Returns Policy or to initiate a cancellation, please contact our customer support team at info@guardedcontent.com.

            

            <h2 className="text-3xl font-bold mt-2">8. Policy Updates</h2>

            GuardedContent reserves the right to update or modify this Membership Returns Policy as necessary. Changes will be effective immediately upon posting on our website.

            <p>This Membership Returns Policy ensures transparency and fairness in handling cancellations and refunds for our membership program, providing clear guidelines for members to follow when seeking to cancel their subscriptions.returns.</p>
        </div>
        <footer className="px-8 py-8 absolute left-0 bottom-0 right-0">
          &copy; GuardedContent, 2024
        </footer>
    </>
}