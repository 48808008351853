import LandingPageComponent from '../components/LandingComponent';

export default function contact() {
    return <>
        <LandingPageComponent />
        <div className="mx-2 my-2">
            <h1 className="text-center text-2xl font-bold">Contact</h1>
            <p className="text-center text-blue-400"><a href="mailto:info@guardedcontent.com">info@guardedcontent.com</a></p>
        </div>
    </>
}