import LandingComponent from '../components/LandingComponent';

export default function Privacy() {
    return <>
        <LandingComponent />
        <div className="p-20">
          <h2 className="text-6xl text-center">
              <strong>Privacy Policy</strong>
          </h2>
          <p className="text-center mt-4">Effective Date: June 18th, 2024</p>
          <br />
          <h3 className="text-4xl">1. Introduction</h3><br />
          <p>Welcome to GuardedContent ("we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information.</p>

          <br /><h3 className="text-4xl">2. Information We Collect</h3><br />

          <div className="px-4">
            <h3 className="text-3xl">2.1 Personal Information</h3><br />
            <span>We may collect personal information that you provide to us directly, such as:</span>
            <ul className="list-disc list-inside">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Payment information</li>
              <li>Usernames and passwords</li>
              <li>Any other information you choose to provide</li>
            </ul>

            <br /><h3 className="text-3xl">2.2 Non-Personal Information</h3><br />
            <span>We may collect non-personal information automatically when you use our platform, including:</span>
            <ul className="list-disc list-inside">
              <li>IP address</li>
              <li>Browser type</li>
              <li>Operating systm</li>
              <li>Device information</li>
              <li>Usage data and browsing history</li>
            </ul>
          </div>

          <br /><h3 className="text-4xl">3. How We Use Your Information</h3><br />
          <span>We use the information we collect to:</span>
          <ul className="list-disc list-inside">
            <li>Provide, operate, and maintain our platform</li>
            <li>Improve, personalize, and expand our platform</li>
            <li>Process transactions and send related information</li>
            <li>Communicate with you, including sending updates and promotional materials</li>
            <li>Monitor and analyse usage and trends to improve user experience</li>
            <li>Detect, prevent, and address technical issues</li>
            <li>Ensure compliance with our terms and conditions</li>
          </ul>

          <br /><h3 className="text-4xl">4. Information Sharing and Disclosure</h3><br />
          <span>We do not sell your personal information to third parties. However, we may share your information with third parties in the following circumstances:</span>

          <ul className="list-disc list-inside">
            <li>With your consent</li>
            <li>With service providers who perform services on our behalf</li>
            <li>To comply with legal obligations</li>
            <li>To protect and defend our rights and property</li>
            <li>In connection with a business transfer, such as a merger or acquisition</li>
          </ul>

          <br /><h3 className="text-4xl">5. Data Security</h3><br />
          <span>We implement appropriate technical and organizational measures to protect your information from unauthorized access, loss, misuse, or alteration. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the absolute security of your information.</span>

          <br /><br /><h3 className="text-4xl">6. Your Rights and Choices</h3><br />
          <span>You have the following rights regarding your personal information:</span>

          <ul className="list-disc list-inside">
            <li>Access: You can request access to the personal information we hold about you.</li>
            <li>Correction: You can request that we correct any inaccuracies in your personal information.</li>
            <li>Deletion: You can request that we delete your personal information, subject to certain exceptions.</li>
            <li>Opt-out: You can opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications.</li>
          </ul>

          <br /><h3 className="text-4xl">7. Third-Party Links</h3><br />
          <span>Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party websites you visit.</span>

          <br /><br /><h3 className="text-4xl">8. Changes to this Privacy Policy</h3><br />
          <span>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our platform and updating the "Effective Date" at the top of this policy.</span>

          <br /><br /><h3 className="text-4xl">9. Contact Us</h3><br />
          <span>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</span><br />
          <span><a href="mailto:info@guardedcontent.com" className="text-blue-500">info@guardedcontent.com</a></span><br /><br />
          <span>By using our platform, you agree to the terms of this Privacy Policy.</span>
        </div>

      <footer className="px-8 py-8 absolute left-0 bottom-0 right-0">
          &copy; GuardedContent, 2024
      </footer>
    </>
}