import logo from '../GCLOGO.png';
import LandingComponent from '../components/LandingComponent';

export default function LandingPage() {
    return <>
        <LandingComponent />

        <div className="mt-48">
            <h1 className="text-2xl font-bold text-center">Protect your content using DRM</h1>
            <h2 className="text-lg font-bold text-center">Coming Soon</h2>
        </div>


        <div className="mt-24 px-8">
            <h1 className="text-2xl font-bold">About Us</h1>
            <p>We provide a content creator platform which utilises Digital Rights Management Technology, to prevent the use of screenshots/screenrecordings of digital media.</p>
        </div>

        <footer className="px-8 py-8 absolute left-0 bottom-0 right-0">
            &copy; GuardedContent, 2024
        </footer>
    </>
}